import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'config/axios'
import 'lang/i18n'

import * as serviceWorker from './serviceWorker';

import * as Sentry from '@sentry/browser';
import { Integrations } from "@sentry/tracing";

if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
	Sentry.init({
		dsn: "https://6a45c510502445abbdd1c5a038b6ff1c@o460464.ingest.sentry.io/5614717",
		environment: process.env.REACT_APP_ENVIRONMENT,
		release: process.env.REACT_APP_BUILD,
		integrations: [
			new Integrations.BrowserTracing(),
		],
		tracesSampleRate: 1.0,
	});
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
