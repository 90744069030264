import React , { useEffect } from 'react'
import useAlert from 'states/hooks/useAlert'
import {on , emit} from 'jetemit'

const EMITS = {
    ALERT:"ALERT"
}


function Emits() {
    const { setAlert } = useAlert();

    useEffect(() => {
        let alertListener = on(EMITS.ALERT, ({msg , type}) => {
            setAlert(type , msg);
        });
        return () => {
            alertListener();
        }
    },[])
    return null;
}

function AlertEmit(msg:string , type:"error"|"warning"|"info"|"success"){
    emit(EMITS.ALERT,{
        msg,
        type
    })
}

export default Emits;
export { 
    AlertEmit
}