import axios from "axios";
import {store} from "../states/stores";
import {AlertEmit} from "../helpers/Emits";
import i18n from 'i18next'

axios.interceptors.response.use(
  response => {
    // console.log(response, "success => from response `axios`");
    if (response && response.status >= 200) {
      return response && (response.data || true);
    }
    return Promise.reject("Some things error happened");
  },
  err => {

    let response = err && err.response;

    if (response.status === 500) {
      AlertEmit(i18n.t("error_500"), "error");
    }
    if (response.status === 502) {
      AlertEmit(i18n.t("error_502"), "error");
    }
    if (response.status === 401) {
      store.account.userLogout();
    }
    if (response.status === 403) {
      AlertEmit(i18n.t("fetchError"), "error");
    }
    if (response.status === 400) {
      // AlertEmit(i18n.t("fetchError"),"error");
    }

    return Promise.reject(response);
  }
);

//Add a request interceptor
axios.interceptors.request.use(config => {
    const {REACT_APP_TOKEN_HEADER_NAME, REACT_APP_TOKEN_HEADER_TYPE} = process.env;
    if (!(config.url || '').startsWith('/api')) {
      config.url = process.env.REACT_APP_BASEURL + (config.url || '');
    }
    if (!config.headers[REACT_APP_TOKEN_HEADER_NAME!]) {
      const token = store.account.token;
      if (token) {
        config.headers[REACT_APP_TOKEN_HEADER_NAME!] = `${REACT_APP_TOKEN_HEADER_TYPE} ${token}`;
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
