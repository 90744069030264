import {SnapshotIn} from "mobx-state-tree";
import {FormListModel} from "./Forms";
import {FormInviteModel, QuestionModel, AnswerModel, ManualUser, BroadcastAutosendSessions} from "./Form";
import Website from "./Website";

// form type
export interface IFormListModel extends SnapshotIn<typeof FormListModel> {
}

export interface IFormInviteList extends SnapshotIn<typeof FormInviteModel> {
}

export interface IFormQuestionListModel extends SnapshotIn<typeof QuestionModel> {
}

export interface IFormQuestionAnswerModel extends SnapshotIn<typeof AnswerModel> {
}

export interface IFormManualUserModel extends SnapshotIn<typeof ManualUser> {
}

export interface IFormAutosendSessionModel extends SnapshotIn<typeof BroadcastAutosendSessions> {
}

export interface IWebsiteModel extends SnapshotIn<typeof Website> {
}

export enum FormType {
    PreTest = 1,
    PostTest = 2,
    SatisfactionSurvey = 3,
    SatisfactionDPCSurvey = 4,
}

export enum UpperFormType {
    SatisfactionSurvey = 1,
    KnowledgeForm = 2,
}

export enum AutoSendSessionAssignmentTypes {
    BEFORE = 1,
    END = 2,
    BOTH = 3
}

export enum QUESTION_TYPE {
    TEXT = 1,
    RADIO,
    CHECKBOX
}


export enum QUESTION_CALCULATION_TYPE {
    GENERAL = 'general',
    SATISFACTION = 'satisfaction',
    ETHICS = 'ethics',
}


export enum InvitationType {
    PRE_TEST = 1,
    POST_TEST,
    SATISFACTION
}