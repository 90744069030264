import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import clsx from 'clsx'
const LoadingPage = () => <Box className="item-wrap"><CircularProgress color="primary" /></Box>


type CenterLoadingType = {
    margin?:boolean
}

const CenterLoading = (p:CenterLoadingType) => (
    <Box className={clsx("center-loading",{"center-loading-margin":p.margin})}>
        <CircularProgress color="primary" />
    </Box>
)

const FullProgress = () => (
    <Backdrop open className="full-page-loading">
        <CircularProgress color="primary" />
    </Backdrop>
)

export default LoadingPage;
export {
    CenterLoading,
    FullProgress
}