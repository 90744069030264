import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import { ROUTE } from 'constants/route';
import CssBaseline from '@material-ui/core/CssBaseline';
import customTheme from './constants/theme';
import PrivateRoute from 'utils/PrivateRoute';
import { FullProgress } from 'components/LoadingPage';
import 'assets/scss/main.scss'
import AppSnackbar from 'components/Alert';
import Emits from 'helpers/Emits';


// Routes
const Login = lazy(() => import('pages/Login'))
const Home = lazy(() => import('pages/Home'))
const Form = lazy(() => import('pages/Form'))
const Forgot = lazy(() => import('pages/Forgot'))

const App = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
        <Router>
          <Suspense fallback={<FullProgress />}>
            <Switch>
              <Route path={ROUTE.LOGIN.link} component={Login} />
              <Route path={ROUTE.FORGOT.link} component={Forgot} />
              <PrivateRoute path={ROUTE.FORM.link} name="Form" component={Form} />
              <PrivateRoute path={ROUTE.MAIN.link} name="Home" component={Home} />
            </Switch>
          </Suspense>
        </Router>
      <AppSnackbar />
      <Emits />
    </ThemeProvider>
  );
}

export default App;
