import React from 'react';
import { types } from "mobx-state-tree";


const Alert = types.model({
    type:types.union(
        types.literal("success"), 
        types.literal("warning"), 
        types.literal("error"), 
        types.literal("info")
    ),
    text:types.optional(types.string , ""),
    show:types.optional(types.boolean,false)
}).actions((self) => {

    function setAlert(type , msg) {
      self.type = type;
      self.text = msg;
      self.show = true;
    }

    function dismissAlert(){
        self.show = false;
    }

    return {
        setAlert,
        dismissAlert
    }
});


export default Alert;