import {types} from "mobx-state-tree";
import UserName from "../../utils/userName";
import Axios from "axios";

export const USER_LOCALSTORAGE_TOKEN_KEY = "USER_TOKEN"

const getToken = () => {
  const {REACT_APP_DEBUG = "false", REACT_APP_TOKEN = ''} = process.env;
  let token = window.localStorage.getItem(USER_LOCALSTORAGE_TOKEN_KEY);
  if (REACT_APP_DEBUG === "true") {
    token = REACT_APP_TOKEN || token;
  }
  return token;
}

const Account = types.model({
  name: types.optional(types.string, ""),
  token: types.optional(types.string, ""),
  checking: types.optional(types.boolean, true),
}).actions((self) => {

  function setLoggedin(data, token, remember) {
    self.name = UserName(data);
    self.token = token;
    window.localStorage.setItem(USER_LOCALSTORAGE_TOKEN_KEY, token)
  }

  function setLoading(value) {
    self.checking = value;
  }

  async function checkUser() {
    const token = getToken()
    try {
      if (token) {
        const {REACT_APP_TOKEN_HEADER_NAME, REACT_APP_TOKEN_HEADER_TYPE} = process.env;
        const ch = await Axios.request({
          method: "GET",
          url: `/user/admin/profile/`,
          headers: {
            [REACT_APP_TOKEN_HEADER_NAME]: `${REACT_APP_TOKEN_HEADER_TYPE} ${token}`
          }
        });
        if (ch) {
          this.setLoggedin(ch, token);
        }
      }
    } catch (e) {

    }
    this.setLoading(false);
  }

  function userLogout() {
    window.localStorage.removeItem(USER_LOCALSTORAGE_TOKEN_KEY);
  }

  return {
    userLogout,
    setLoggedin,
    checkUser,
    setLoading
  }

}).views((self) => ({

  get isLogged() {
    return !!self.token
  }

}));

export default Account;