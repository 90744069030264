import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EnLang from './en.json'
import FrLang from './fr.json'

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: {
        translation:EnLang
    },
    fr: {
        translation:FrLang
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: process.env.REACT_APP_LANGUAGE,
        // fallbackLng:"en",
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
});
export default i18n;
