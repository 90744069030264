import React from 'react';
import { types } from "mobx-state-tree";

const Website = types.model({
    id:types.maybeNull(types.number),
    name:types.string,
    logo:types.maybeNull(types.string),
    title:types.string,
    domain:types.maybeNull(types.string),
    description:types.maybeNull(types.string),
    primary_color:types.maybeNull(types.string),
    secondary_color:types.maybeNull(types.string),
    contact_us_text:types.maybeNull(types.string),
    about_us_text:types.maybeNull(types.string),
    terms_text:types.maybeNull(types.string),
    who_we_are:types.maybeNull(types.string),
    who_we_are_bar:types.maybeNull(types.string),
    contact_us_description:types.maybeNull(types.string),
    contact_us_address:types.maybeNull(types.string),
    contact_us_email:types.maybeNull(types.string),
    contact_us_phone:types.maybeNull(types.string),
    contact_us_fax:types.maybeNull(types.string)
});

export default Website;